"use client";

import React, { useState } from "react";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@/components/ui/popover";
import { Button } from "@/components/ui/button";
import { Badge } from "@/components/ui/badge";
import { Checkbox } from "@/components/ui/checkbox";
import { ChevronDown, ChevronUp, Loader2 } from "lucide-react";
import formatName from "../utils/formatName";
import { usePublishers } from "@/context/PublisherContext";
import { Avatar, AvatarImage, AvatarFallback } from "@/components/ui/avatar";
import * as CheckboxPrimitive from "@radix-ui/react-checkbox";
import { cn } from "@/lib/utils";
import { Switch } from "@/components/ui/switch";

interface CheckboxWithIndeterminateProps
  extends React.ComponentProps<typeof CheckboxPrimitive.Root> {
  indeterminate?: boolean;
}

const CheckboxWithIndeterminate = React.forwardRef<
  React.ElementRef<typeof CheckboxPrimitive.Root>,
  CheckboxWithIndeterminateProps
>((props, ref) => {
  const { indeterminate, ...checkboxProps } = props;

  return (
    <Checkbox
      ref={ref}
      {...checkboxProps}
      data-state={
        indeterminate
          ? "indeterminate"
          : checkboxProps.checked
          ? "checked"
          : "unchecked"
      }
    />
  );
});

CheckboxWithIndeterminate.displayName = "CheckboxWithIndeterminate";

export function SourceSelectionPopover({ className }: { className?: string }) {
  const {
    categories,
    publishers,
    selectedPublisherIds,
    togglePublisher,
    toggleCategoryPublishers,
    getSelectedCount,
    getCategorySelectedCount,
    isCategoryFullySelected,
    isCategoryPartiallySelected,
    selectAllPublishers,
    deselectAllPublishers,
    isLoading,
  } = usePublishers();

  const [open, setOpen] = useState(false);

  const handleWheel = (e: React.WheelEvent) => {
    e.stopPropagation();
  };

  return (
    <Popover open={open} onOpenChange={setOpen}>
      <PopoverTrigger asChild>
        <Button
          variant="outline"
          className="flex items-center gap-2 h-full border-none"
        >
          <span>Sources</span>
          <Badge
            variant="secondary"
            className="rounded-full w-[30px] text-center"
          >
            {isLoading ? (
              <Loader2 className="h-4 w-4 animate-spin" />
            ) : (
              getSelectedCount()
            )}
          </Badge>
          {open ? (
            <ChevronUp className="h-4 w-4" />
          ) : (
            <ChevronDown className="h-4 w-4" />
          )}
        </Button>
      </PopoverTrigger>
      <PopoverContent
        className="w-[740px] mt-1 p-4 pb-2 absolute right-[-218px]"
        align="start"
        side="bottom"
        sideOffset={8}
      >
        <div className="space-y-0">
          {/* Category Headers - Fixed */}
          <div className="grid grid-cols-5 gap-4 mb-2">
            {categories.map((category) => {
              const categoryHasPublishers = publishers.some((pub) =>
                pub.categoryIds.includes(category.id)
              );

              return (
                <div
                  key={category.id}
                  className="w-[125px] flex items-center gap-2 px-1"
                >
                  <CheckboxWithIndeterminate
                    id={category.id}
                    checked={isCategoryFullySelected(category.id)}
                    indeterminate={isCategoryPartiallySelected(category.id)}
                    onCheckedChange={() =>
                      toggleCategoryPublishers(category.id)
                    }
                    disabled={!categoryHasPublishers}
                    className={
                      !categoryHasPublishers
                        ? "opacity-50 cursor-not-allowed"
                        : ""
                    }
                  />
                  <label
                    htmlFor={category.id}
                    className={cn(
                      "text-black text-xs font-medium leading-none",
                      !categoryHasPublishers && "opacity-50"
                    )}
                  >
                    {category.name}
                    <Badge
                      variant="secondary"
                      className={cn(
                        "ml-2 rounded-full w-[30px]",
                        !categoryHasPublishers && "opacity-50"
                      )}
                    >
                      {getCategorySelectedCount(category.id)}
                    </Badge>
                  </label>
                </div>
              );
            })}
          </div>

          {/* Publishers Grid - Single Scrollbar */}
          <div
            className="h-[180px] overflow-y-auto scrollbar-hide"
            onWheel={handleWheel}
          >
            <div className="grid grid-cols-5 gap-4 py-2 px-1 bg-[#f6f6f6]">
              {categories.map((category) => (
                <div key={category.id} className="space-y-2">
                  {publishers
                    .filter((pub) => pub.categoryIds.includes(category.id))
                    .map((publisher) => {
                      //console.log(`Publisher ${publisher.name} image:`, publisher.image);
                      return (
                        <div
                          key={publisher.id}
                          onClick={() => togglePublisher(publisher.id)}
                          className={cn(
                            "w-[125px] flex items-center gap-2 p-2 rounded-lg cursor-pointer transition-all",
                            "bg-white hover:bg-gray-50",
                            selectedPublisherIds.includes(publisher.id)
                              ? "border border-[#592cff]"
                              : "border border-[#d9d9d9]"
                          )}
                        >
                          <div className="flex items-center gap-2 min-w-0 w-full">
                            <Avatar className="h-6 w-6 border border-gray-200">
                              <AvatarImage
                                src={`/publishers/${publisher.image}-40x40@4x.png`}
                                alt={publisher.name}
                              />
                              <AvatarFallback
                                className="text-xs"
                                delayMs={5000}
                              >
                                {publisher.name
                                  .split(" ")
                                  .map((word) => word[0])
                                  .join("")
                                  .slice(0, 2)
                                  .toUpperCase()}
                              </AvatarFallback>
                            </Avatar>
                            <span className="text-[10px] font-medium leading-none truncate">
                              {publisher.name}
                            </span>
                          </div>
                        </div>
                      );
                    })}
                </div>
              ))}
            </div>
          </div>

          {/* Add Select All toggle at bottom */}
          <div className="flex justify-end items-center gap-2 pt-2 border-t">
            <span className="text-sm font-medium">Select all</span>
            <Switch
              checked={publishers.length === selectedPublisherIds.length}
              onCheckedChange={(checked) => {
                if (checked) {
                  selectAllPublishers();
                } else {
                  deselectAllPublishers();
                }
              }}
            />
          </div>
        </div>
      </PopoverContent>
    </Popover>
  );
}

"use client";

import React, { useEffect, useState } from "react";
import { cn } from "@/lib/utils";
import QuestionInput from "./QuestionInput";
import { SourceSelectionPopover } from "./SourceSelectionPopover";
import { usePublishers } from "@/context/PublisherContext";
import { useQuestionSubmit } from "@/hooks/useQuestionSubmit";
import { RecommendedQuestions } from "./RecommendedQuestions";
import { useRouter } from "next/navigation";
import { apiClient } from "@/utils/apiClient";

interface SearchCoreProps {
  className?: string;
  onSearchComplete?: () => void;
}

export function SearchCore({ className, onSearchComplete }: SearchCoreProps) {
  const router = useRouter();
  const { selectedPublisherIds, isLoading } = usePublishers();
  const { submitQuestion } = useQuestionSubmit();
  const [recommendedQuestions, setRecommendedQuestions] = useState<string[]>(
    []
  );
  const [isLoadingQuestions, setIsLoadingQuestions] = useState(true);

  const noSourcesSelected = selectedPublisherIds.length === 0;

  useEffect(() => {
    const fetchRecommendedQuestions = async () => {
      try {
        const data = await apiClient.get("/api/recommended-questions");
        setRecommendedQuestions(data.questions);
      } catch (error) {
        console.error("Failed to fetch recommended questions:", error);
      } finally {
        setIsLoadingQuestions(false);
      }
    };

    fetchRecommendedQuestions();
  }, []); // Run once on mount

  const handleSubmit = async (question: string) => {
    try {
      // Add loading state if needed
      const response = await submitQuestion(
        question,
        undefined,
        selectedPublisherIds,
        0
      );

      if (response?.thread_id) {
        // Use router.push instead of redirect
        router.push(`/threads/${response.thread_id}`);
        onSearchComplete?.();
      }
    } catch (error) {
      console.error("Error submitting question:", error);
    }
  };

  const handleRecommendedQuestionClick = (question: string) => {
    handleSubmit(question);
  };

  return (
    <div className={cn("w-full flex flex-col gap-2", className)}>
      <h1 className="text-4xl font-bold font-gilroy text-center mb-4">
        Let&apos;s get straight to the gist.
      </h1>
      <div className="relative w-full max-w-3xl mx-auto">
        <QuestionInput
          onSubmit={handleSubmit}
          disabled={isLoading || noSourcesSelected}
          placeholder={
            isLoading
              ? "Loading publishers..."
              : noSourcesSelected
              ? "Select sources to ask a question..."
              : "What would you like to know?"
          }
          rightElement={<SourceSelectionPopover />}
          noPublishersSelected={noSourcesSelected}
        />
      </div>
      <div className="h-[40px] flex items-center justify-center">
        {!isLoadingQuestions && (
          <RecommendedQuestions
            questions={recommendedQuestions}
            onQuestionClick={handleRecommendedQuestionClick}
          />
        )}
      </div>
    </div>
  );
}

import React, { useState, FormEvent, ChangeEvent } from "react";
import { ChevronDown } from "lucide-react";
import { cn } from "@/lib/utils";
import Image from "next/image";
interface QuestionInputProps {
  onSubmit: (question: string) => void;
  placeholder?: string;
  containerClassName?: string;
  textareaClassName?: string;
  disabled?: boolean;
  rightElement?: React.ReactNode;
  noPublishersSelected?: boolean;
}

const QuestionInput: React.FC<QuestionInputProps> = ({
  onSubmit,
  placeholder = "Ask anything...",
  containerClassName,
  textareaClassName,
  disabled = false,
  rightElement,
  noPublishersSelected = false,
}) => {
  const [question, setQuestion] = useState<string>("");
  const [showShiftReturn, setShowShiftReturn] = useState<boolean>(false);

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (question.trim()) {
      onSubmit(question.trim());
      setQuestion("");
      setShowShiftReturn(false);
    }
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
    if (e.key === "Enter" && !e.shiftKey) {
      e.preventDefault();
      if (!disabled && question.trim() && !noPublishersSelected) {
        handleSubmit(e as unknown as FormEvent<HTMLFormElement>);
      }
    }
  };

  const handleChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
    setQuestion(e.target.value);
    setShowShiftReturn(e.target.value.trim() !== "");
  };

  return (
    <div className={cn("relative", containerClassName)}>
      <form
        onSubmit={handleSubmit}
        className="w-full max-w-3xl mx-auto relative"
      >
        <div className="relative w-full h-16 px-[25px] py-3 bg-white rounded-[100px] border border-black/20 flex items-center">
          <textarea
            value={question}
            onChange={handleChange}
            onKeyDown={handleKeyDown}
            placeholder={placeholder}
            className={cn(
              "flex-1 h-12 resize-none overflow-hidden outline-none focus:outline-none",
              "disabled:bg-white disabled:opacity-100",
              textareaClassName
            )}
            rows={1}
            disabled={disabled}
            style={{ paddingTop: "12px" }}
          />
          <div className="flex items-center h-full shrink-0">
            {rightElement && (
              <>
                <div className="h-full w-[1px] bg-black/20 mx-2" />
                {rightElement}
                <div className="h-full w-4" />
              </>
            )}
            <button
              type="submit"
              disabled={disabled || !question.trim() || noPublishersSelected}
              className={cn(
                "flex items-center justify-center w-10 h-10 rounded-full transition-opacity",
                "hover:opacity-80",
                "disabled:opacity-50 disabled:cursor-not-allowed"
              )}
            >
              <Image
                src="/icons/Send.svg"
                width={40}
                height={40}
                alt="Send"
                priority
                className="rounded-full"
              />
            </button>
          </div>
        </div>
      </form>
      <div className="h-4 mt-1 w-full max-w-3xl">
        {showShiftReturn && (
          <div className="text-sm text-gray-500 text-right pr-1">
            Use{" "}
            <span className="font-medium text-[#241c15] rounded">
              shift+return
            </span>{" "}
            for new line
          </div>
        )}
      </div>
    </div>
  );
};

export default QuestionInput;
